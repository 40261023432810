import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../header/CartContext";
import { FavoriteContext } from "../header/FavoriteContext";
import { ToastContainer, toast } from "react-toastify";
import Petshop from "../../assets/images/Petshop.svg";
import StarRating from "../../StarRating";
import axios from "axios";
import FlexBannerService from "../Service/FlexBannerService";
import CategoryService from "../Service/CategoryService";
import ProductService from "../Service/ProductService";

const ProductRelated = ({ productId, onViewMore }) => {
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const { addToFavorites, isInFavorites, removeFromFavorites } =
    useContext(FavoriteContext);
  const { addToCart } = useContext(CartContext);
  const navigate = useNavigate();

  const [categories, setCategories] = useState([]);
  const [productflex,setProductFlex]= useState()

  useEffect(() => {
    const fetchCategories = async () => {
        try {
            const response = await CategoryService.fetchAllCategorise(); 
            const data = response.data;

            const childCategories = data.flatMap((category) => category.children);
            setCategories(childCategories);
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    fetchCategories();
}, []);

  useEffect(() => {
FlexBannerService.fetchFlexImage()
      .then((response) => {
        if (response.data) {
          const productflex = response.data.find(
            (item) => item.position === "Product Flex 1"
          );
          setProductFlex(productflex || null);
        } else {
          console.error("Unexpected response format", response);
        }
      })
      .catch((error) => {
        console.error("Error fetching flex panels:", error);
      });
  }, []);

  useEffect(() => {
    const fetchRelatedProducts = async () => {
        try {
            const response = await ProductService.fetchProductShows();
            const data = response.data; 
            
            const currentProduct = data.find((product) => product._id === productId);

            if (currentProduct) {
                const related = data
                    .filter(
                        (product) =>
                            product.category === currentProduct.category &&
                            product._id !== productId
                    )
                    .slice(0, 3);

                setRelatedProducts(related);
            } else {
                setRelatedProducts([]);
            }
        } catch (err) {
            setError(err.message); 
        } finally {
            setLoading(false);
        }
    };

    fetchRelatedProducts();
}, [productId]);


  const handleAddToFavorites = (product) => {
    const productWithDetails = {
      ...product,
      variant: selectedVariant,
      price: selectedVariant ? selectedVariant.price : product.price,
      discountPrice: selectedVariant
        ? selectedVariant.discount
        : product.discountPrice,
    };

    if (isInFavorites(product._id)) {
      removeFromFavorites(product._id);
      // toast.info("Product removed from favorites", {
      //   position: "top-right",
      //   theme: "colored",
      //   autoClose: 1000,
      // });
    } else {
      addToFavorites(productWithDetails);
      toast.success("Product added to favorites", {
        position: "top-right",
        theme: "colored",
        autoClose: 1000,
      });
    }
  };

  const handleAddToCart = (product) => {
    const selectedVariantDetails = selectedVariant;
    if (!selectedVariantDetails) {
      console.error("Selected variant not found");
      return;
    }

    const productWithDetails = {
      ...product,
      quantity: 1,
      variant: selectedVariant,
      price: selectedVariantDetails.price,
    };

    addToCart(productWithDetails);
    toast.success("Product added to cart", {
      position: "top-right",
      theme: "colored",
      autoClose: 1000,
    });
  };

  const handleViewMore = () => {
    if (relatedProducts.length > 0) {
      const id = relatedProducts[0].category;
      const category = getCategoryNameById(id);

      if (category) {
        navigate("/product", { state: { category, id } });
        console.log("Category:", category);
        console.log("ID:", id);
      } else {
        console.error("Category name not found");
      }
    } else {
      console.error("No related products available");
    }
  };

  const handleProductClick = (productId, productName) => {
    const productSlug = productName
    navigate(`/product/${productSlug}/${productId}`);
  };

  const getCategoryNameById = (id) => {
    const findCategoryName = (categories, id) => {
      for (const category of categories) {
        if (category._id === id) {
          return category.name.en;
        }
        if (category.children && category.children.length > 0) {
          const childCategoryName = findCategoryName(category.children, id);
          if (childCategoryName) {
            return childCategoryName;
          }
        }
      }
      return null;
    };

    return findCategoryName(categories, id) || "Unknown Category";
  };

  if (loading)
    return <div className="text-center text-gray-600">Loading...</div>;
  if (error)
    return <div className="text-center text-primary-color">Error: {error}</div>;

  return (
    <div className="">
      <div className="">
        <div>
        {productflex && productflex.imageUrl && (
          <img
            src={productflex.imageUrl}
            alt="Pet Shop"
            className="w-full petshop-banner-img "
          />
        )}
        </div>
        <div className="flex justify-between pt-3">
          <div>
            {" "}
            <h5 className="text-lg pb-3">Related Product</h5>
          </div>
          <div
            className="text-sm font-semibold hover:text-primary-color mt-1 ml-16 cursor-pointer"
            onClick={handleViewMore}
          >
            View more
          </div>
        </div>

        <div className=" pb-12">
          {relatedProducts.map((product) => (
            <div
              key={product._id}
              className="relate-box bg-white w-70 flex items-center p-2 mb-2"
            >
              <div className="mr-4">
                <img
                  src={product.image[0]}
                  alt={product.title.en}
                  className="w-20 h-20"
                />
              </div>
              <div className="flex flex-col">
                <div
                  className="font-bold cursor-pointer line-clamp-2 "
                  onClick={() => handleProductClick(product._id,product.slug)}
                >
                  {product.title.en}
                </div>
                <div className="flex gap-2 mt-1">
                  <div className="text-base text-primary-color font-semibold">
                    ₹{product.prices.price}
                  </div>
                  <div className="text-base line-through text-gray-600">
                    ₹{product.prices.originalPrice}
                  </div>
                </div>
                <div className="flex justify-start items-center ">
                  {/* <button
                    onClick={() => handleAddToCart(product)}
                    className="mt-2 border border-slate-400 bg-white text-black py-1 px-2 rounded-full hover:bg-primary-color hover:text-white"
                  >
                    Add to Cart
                  </button> */}
                  {/* <StarRating rating={product.rating} /> */}
                  {/* <button
                    className="border-none bg-white rounded-full p-2 transition duration-300 ease-in-out"
                    onClick={() => handleAddToFavorites(product)}
                  >
                    {isInFavorites(product._id) ? (
                      <svg
                        className="w-6 h-6 text-primary-color mt-2"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M3.172 5.172a4 4 0 015.656 0L10 6.344l1.172-1.172a4 4 0 015.656 5.656l-1.172 1.172-5.656 5.656-5.656-5.656L3.172 10.83a4 4 0 010-5.656z" />
                      </svg>
                    ) : (
                      <svg
                        className="w-6 h-6 text-primary-color mt-2"
                        width="21"
                        height="17"
                        viewBox="0 0 21 17"
                        fill="red"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.6916 1.76514C20.3283 3.97998 19.9416 7.60107 18.0783 9.53467L11.8908 15.8276C11.5392 16.1792 11.0822 16.3901 10.59 16.3901C10.0627 16.3901 9.60562 16.1792 9.25406 15.8276L3.10172 9.53467C1.20328 7.60107 0.816559 3.97998 3.45328 1.76514C5.49234 0.0424805 8.62125 0.288574 10.59 2.29248C12.5236 0.288574 15.6525 0.0424805 17.6916 1.76514ZM16.8478 8.33936C18.1486 7.03857 18.3947 4.57764 16.6017 3.03076C15.2306 1.90576 13.1212 2.08154 11.8205 3.41748L10.59 4.68311L9.35953 3.41748C8.02359 2.08154 5.91422 1.90576 4.54312 3.06592C2.75015 4.57764 2.99625 7.03857 4.29703 8.33936L10.4494 14.6323C10.5548 14.7026 10.6252 14.7026 10.6955 14.6323L16.8478 8.33936Z"
                          fill="#F03221"
                        />
                      </svg>
                    )}
                  </button> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ProductRelated;
