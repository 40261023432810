import React, { useContext, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../header/CartContext";
import { FavoriteContext } from "../header/FavoriteContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StarRating from "../../StarRating";

const NewArrival = ({ apiProducts }) => {
  const { addToCart } = useContext(CartContext);
  const { addToFavorites, isInFavorites, removeFromFavorites } =
    useContext(FavoriteContext);
  const scrollContainerRef = useRef(null);
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    const allProducts = apiProducts;
    const recentProducts = allProducts.filter((product) => {
      const productDate = new Date(product.updatedAt);
      const tenDaysAgo = new Date();
      tenDaysAgo.setDate(tenDaysAgo.getDate() - 10);
      return productDate >= tenDaysAgo;
    });
    setProducts(allProducts);
    setFilteredProducts(recentProducts);
  }, [apiProducts]);

  const [quantity, setQuantity] = useState(1);



  const handleProductClick = (productId, productName) => {
    const productSlug = productName
    navigate(`/product/${productSlug}/${productId}`);
  };

  const handleProductAll = () => {
    navigate("/product", { state: { section: "New Arrival" } });
  };

  const [hoveredProductId, setHoveredProductId] = useState(null);

  const handleMouseEnter = (productId) => {
    setHoveredProductId(productId);
  };

  const handleMouseLeave = () => {
    setHoveredProductId(null);
  };

  if (filteredProducts.length === 0) {
    return null;
  }

  const handleScrollLeft = () => {
    scrollContainerRef.current.scrollBy({
      left: -200, 
      behavior: "smooth",
    });
  };

  const handleScrollRight = () => {
    scrollContainerRef.current.scrollBy({
      left: 200, 
      behavior: "smooth",
    });
  };

  return (
    <div className="relative">
      <div className="new-full-container">
        <div className="new-arrival-container flex justify-between pb-2">
          <div className="flex-col">
            <h1 className="font-bold text-5xl">New Arrivals</h1>
            <p className="text-sm mt-3">
              Choose your necessary products from this feature category
            </p>
          </div>
          <div className="flex">
            <button
              className="relative border-none h-11 rounded-3xl px-7 py-2 text-sm font-bold text-primary-color bg-light-color cursor-pointer overflow-hidden transition-all duration-300 hover:text-white hover:bg-primary-color whitespace-nowrap"
              onClick={handleProductAll}
            >
              View More
            </button>
          </div>
        </div>

        <div className="new-arrival-product relative">
          <button
            onClick={handleScrollLeft}
            className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-transparent text-[#504a4a] border-2 border-[#7c7b7b] rounded-full w-10 h-10 flex justify-center items-center cursor-pointer shadow-lg hover:bg-primary-color hover:text-white transition-all duration-300"
          >
                &#9664; 
          </button>

          <div
            className="scroll-container snap-x flex overflow-x-auto pt-2 pb-3 text-center scrollbar-hide scroll-smooth"
            ref={scrollContainerRef}
          >
          <div className="new-arrival-box flex justify-between space-x-8">
            {filteredProducts.map((product) => (
              <div key={product._id} className="flex-shrink-0 w-64 snap-center">
                <div className="bg-white rounded shadow-lg p-4">
                  <div className="bg-secondary-color text-white text-sm py-1 w-16 mr-2 whitespace-nowrap rounded-r-full top-2 left-0">
                  Off {((product.prices.discount / product.prices.originalPrice)*100).toFixed(0)}%
                  </div>
                  <img
                    src={product.image[0]}
                    alt={product.title.en}
                    className="w-full  cursor-pointer"
                    onClick={() => handleProductClick(product._id,product.slug)}
                  />
                  <h1
                    className="text-sm font-bold mt-2 cursor-pointer  line-clamp-2 "
                    onClick={() => handleProductClick(product._id,product.slug)}
                  >
                    {product.title.en}
                  </h1>
                  <div className="flex justify-center mt-2">
                    <StarRating rating={product.rating} />
                  </div>
                  <div className="flex items-center justify-center mt-2 space-x-2">
                    <h3 className="text-lg font-semibold text-primary-color">
                      ₹{product.prices.price}
                    </h3>
                    <h4 className="text-base line-through text-gray-500">
                      ₹{product.prices.originalPrice}
                    </h4>
                  </div>
                </div>
              </div>
            ))}
          </div>
          </div>

          <button
            onClick={handleScrollRight}
            className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-transparent text-[#504a4a] border-2 border-[#7c7b7b] rounded-full w-10 h-10 flex justify-center items-center cursor-pointer shadow-lg hover:bg-primary-color hover:text-white transition-all duration-300"
          >
             &#9654; 
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewArrival;
