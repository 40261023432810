import React, { useContext, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { CartContext } from "../header/CartContext";
import { FavoriteContext } from "../header/FavoriteContext";
// import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import SuccessIcon from "../../assets/images/success.svg";
import IncrementIcon from "../../assets/images/incrementicon.svg";
import DecrementIcon from "../../assets/images/decrementicon.svg";
import Header from "../header/header";
import Navbar from "../navbar/navbar";
import Footer from "../Footer/Footer";
import TabletNavbar from "../navbar/TabletNavbar";
import { FaStar } from "react-icons/fa";
import Productinfo from "./Productinfo";
import ProductRelated from "./ProductRelated";
import BuyIcon from "../../assets/images/Buyicon.svg";
import FIcon from "../../assets/images/f_icon.svg";
import TIcon from "../../assets/images/t_icon.svg";
import InIcon from "../../assets/images/in_icon.svg";
import LoadingSpinner from "../Loader/LoadingSpinner";
import { notifyError, notifySuccess } from "../../toast";
import axios from "axios";
import LoginPopup from "../Authentication/LoginPopup";
import StarRating from "../../StarRating";
import AttributesService from "../Service/AttributesService";
import { baseURL } from "../api";
import ProductService from "../Service/ProductService";
import { Helmet } from "react-helmet";
import { titleName } from "../Container/Images";

const ProductView = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState();
  const [selectedImage, setSelectedImage] = useState("");
  const { addToFavorites, isInFavorites, removeFromFavorites } =
    useContext(FavoriteContext);
  const { addToCart } = useContext(CartContext);
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(1);
  const [reviews, setReviews] = useState([]);
  const [averageRating, setAverageRating] = useState(0);
  const [hoveredProductId, setHoveredProductId] = useState(null);
  const [isLoginOpen, setIsLoginOpen] = useState(false);

  const [variantData, setVariantData] = useState("");
  useEffect(() => {
    AttributesService.fetchAllAttribute()
      .then((response) => {
        console.log(response.data);
        setVariantData(response.data);
      })
      .catch((err) => {
        console.error(err.message);
      });
  }, []);

  const [isFavorite, setIsFavorite] = useState(false);

  // Handle favorite toggle
  const handleToggleFavorite = () => {
    setIsFavorite(!isFavorite); // Toggle favorite state
    handleAddToFavorites(product); // Call the function to add/remove from favorites
  };

  useEffect(() => {
    setReviews([]);

    const fetchProduct = async () => {
      try {
        const response = await ProductService.fetchProductId(id);
        const data = response.data || null;

        console.log(data);

        if (Array.isArray(variantData) && data) {
          let varr = data.variants.map((variant) => {
            variantData.forEach((variantDataItem) => {
              variantDataItem.variants.forEach((id) => {
                if (id._id === variant[Object.keys(variant)[0]]) {
                  variant["variantName"] = id.name.en;
                }
              });
            });
            return variant;
          });
          data["variants"] = varr;
        } else {
          console.error(
            "variantData is not an array or product data is missing:",
            variantData,
            data
          );
        }

        setProduct(data);
        setSelectedImage(data.image[0]);
        setSelectedVariant(data.variants[0]);
      } catch (err) {
        console.error("Error fetching product:", err.message);
      } finally {
        setLoading(false);
      }

      window.scrollTo(0, 0);
    };

    const fetchReviews = async () => {
      try {
        const response = await ProductService.fetchProductReview(id);
        if (!response.ok) throw new Error("Network response was not ok");

        const data = response.data;

        if (Array.isArray(data)) {
          setReviews(data);
          const totalRating = data.reduce(
            (acc, review) => acc + review.rating,
            0
          );
          const avgRating = data.length > 0 ? totalRating / data.length : 0;
          setAverageRating(avgRating);
        } else {
          console.error("Invalid data format for reviews:", data);
        }
      } catch (error) {
        console.error("Error fetching reviews:", error.message);
      }
    };

    fetchProduct();
    fetchReviews();
  }, [id, variantData]);

  const handleMouseEnter = (productId) => {
    setHoveredProductId(productId);
  };

  const handleCloseLogin = () => {
    setIsLoginOpen(false);
  };

  const handleMouseLeave = () => {
    setHoveredProductId(null);
  };

  const handleAddToFavorites = (product) => {
    const productWithDetails = {
      ...product,
      variant: selectedVariant,
      price: selectedVariant ? selectedVariant.price : product.prices.price,
      discountPrice: selectedVariant
        ? selectedVariant.discount
        : product.prices.discount,
    };

    if (isInFavorites(product._id)) {
      removeFromFavorites(product._id);
    } else {
      addToFavorites(productWithDetails);
    }
  };

  const handleIncrement = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrement = () => {
    setQuantity((prevQuantity) => Math.max(1, prevQuantity - 1));
  };

  const handleVariantSelect = (variant) => {
    setSelectedVariant(variant);
  };
  const toggleFavorite = () => {
    handleAddToFavorites(product);
  };

  if (loading) return <div className="text-center text-gray-600"></div>;
  if (error)
    return <div className="text-center text-primary-color">Error: {error}</div>;
  if (!product)
    return <div className="text-center text-gray-600">No product found</div>;

  const title = product.title.en;
  const description = product.description.en;
  const price = product.prices.price;
  const brand = product.brand;

  const mateTitle = product.metaTitle;
  const mateDescription = product.metaDescription;
  const mateBrand = product.mateBrand;

  console.log(mateDescription, mateTitle, mateBrand);

  console.log(price);
  const handleImageSelect = (image) => {
    setSelectedImage(image);
  };

  const handleAddToCart = () => {
    const isVariantSelected =
      selectedVariant !== null && selectedVariant !== undefined;
    const priceString = isVariantSelected
      ? selectedVariant.price
      : product.prices.price;
    const price =
      typeof priceString === "string"
        ? parseFloat(priceString.replace("₹", ""))
        : parseFloat(priceString);

    const variantName = isVariantSelected ? selectedVariant.name : null;
    const totalPrice = quantity * price;

    const productWithDetails = {
      ...product,
      productId: product._id,
      variant: isVariantSelected ? selectedVariant : null,
      variantName: variantName,
      quantity: quantity,
      price: `₹${price.toFixed(2)}`,
      totalPrice: `₹${totalPrice.toFixed(2)}`,
    };
    addToCart(productWithDetails);
    notifySuccess("Successfully added to cart");
  };

  const handleBuyNow = () => {
    const price = selectedVariant
      ? parseFloat(selectedVariant.price.replace("₹", ""))
      : product.prices.price;

    const variantName = selectedVariant ? selectedVariant.name : null;
    const totalPrice = 1 * price;

    const productWithDetails = {
      ...product,
      variant: selectedVariant,
      variantName: variantName,
      quantity: quantity,
      price: `₹${price.toFixed(2)}`,
      totalPrice: `₹${totalPrice.toFixed(2)}`,
    };

    addToCart(productWithDetails);
    const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";

    if (isLoggedIn) {
      navigate("/checkout");
      localStorage.setItem("isLoggedIn", "true");
    } else {
      setIsLoginOpen(true);
    }
  };

  // const handleProductAll = () => {
  //   navigate("/product");
  // };

  return (
    <>
      <div className="fixed z-10 w-full top-0">
        <Header className="bg-white shadow-lg sm:px-4 md:px-6 lg:px-8 xl:px-10" />
        <Navbar className="bg-gray-800 text-white py-2 sm:py-3 md:py-4 lg:py-5 xl:py-6" />
      </div>
      <>
        <Helmet>
          <title>
            {mateTitle || titleName} | {title || "Product"}
          </title>
          <meta name="description" content={mateDescription || description} />
          <meta name="brand" content={mateBrand || brand} />
          <meta property="og:title" content={mateTitle || title} />
          <meta
            property="og:description"
            content={mateDescription || description}
          />
          <meta property="og:brand" content={mateBrand || brand} />
          <meta property="og:price:amount" content={price} />
          <meta property="og:price:currency" content="INR" />

          <meta name="twitter:title" content={mateTitle || title} />
          <meta
            name="twitter:description"
            content={mateDescription || description}
          />
          <meta name="twitter:brand" content={mateBrand || brand} />
          <meta name="twitter:image" content={product.image[0]} />
        </Helmet>
      </>
      <div>
        <div className="bg-slate-100 pt-28">
          {/* <ToastContainer /> */}
          <div className="product-view-container p-10">
            <div className="product-view-text mb-4">
              <div className="flex">Home / Product</div>
            </div>
            <div className="product-view-banner flex gap-5">
              <div className="product-view-mini flex flex-col gap-2 custom-scrollbar-mini">
                {product.image.map((img, index) => (
                  <div
                    key={index}
                    onClick={() => handleImageSelect(img)}
                    className="cursor-pointer"
                  >
                    <img
                      src={img}
                      alt=""
                      className="w-16 h-16 border border-solid"
                    />
                  </div>
                ))}
              </div>

              <div className="product-view-main-img   ">
                <img src={selectedImage} alt="" className="w-full  rounded  " />
              </div>

              <div className="product-view-banner-details">
                <div>
                  <h1 className="flex text-xl font-bold">{title}</h1>
                  <div className="flex gap-4 text-sm pt-2">
                    <p className="mt-0 p-1">
                      <StarRating rating={product.rating} />
                    </p>
                    <div className="mt-3">({reviews.length}) Reviews</div>
                  </div>

                  <div className="flex gap-2 pt-3">
                    <h2 className="text-5xl text-secondary-color font-bold">
                      ₹
                      {selectedVariant
                        ? parseFloat(selectedVariant.price).toFixed(2)
                        : parseFloat(product.prices.price).toFixed(2)}
                    </h2>
                    <h3 className="text-xl font-semibold text-gray-400 line-through pt-1">
                      ₹
                      {selectedVariant
                        ? parseFloat(selectedVariant.originalPrice).toFixed(2)
                        : parseFloat(product.prices.originalPrice).toFixed(2)}
                    </h3>
                  </div>
                  <p className="text-sm text-gray-500 pt-4 text-justify hyphenate">
                    {description}
                  </p>

                  <div className="flex items-center gap-3 pt-4">
                    <div className="text-lg font-bold uppercase">Variant:</div>
                    <div className="product-view-variant-options flex gap-1">
                      {product.variants.map((variant) => (
                        <div
                          key={variant.productId}
                          className={`rounded p-1 cursor-pointer ${
                            selectedVariant &&
                            selectedVariant.productId === variant.productId
                              ? "bg-black text-white"
                              : "bg-transparent text-black"
                          }`}
                          onClick={() => handleVariantSelect(variant)}
                        >
                          {variant.variantName}
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="text-lg items-center font-poppins text-green-600 pt-4 font-semibold">
                    <img src={SuccessIcon} alt="" className="mr-1 mt-1" />
                    {product.stock > 0 ? "In Stock" : "Out of Stock"}
                  </div>

                  {/* Quantity selector */}
                  <div className="addcarts-btn flex pt-4">
                    <div className="bg-white p-2 rounded-full h-7 flex items-center">
                      <button
                        className="border-none bg-gray-300 rounded-full h-7 w-7 flex items-center justify-center"
                        onClick={handleDecrement}
                      >
                        <img
                          src={DecrementIcon}
                          alt="decrement"
                          className="cursor-pointer"
                        />
                      </button>
                      <span className="mx-2">{quantity}</span>
                      <button
                        className="border-none bg-gray-300 rounded-full h-7 w-7 flex items-center justify-center"
                        onClick={handleIncrement}
                      >
                        <img
                          src={IncrementIcon}
                          alt="increment"
                          className="cursor-pointer"
                        />
                      </button>
                    </div>

                    {/* Add to Cart and Add to Favorites buttons */}
                    <div className=" flex gap-4 ml-3">
                      <button
                        className="cart-button border bg-white border-moderate-color rounded-full h-10 w-10"
                        onClick={handleAddToCart}
                      >
                        <div className="flex items-center justify-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="red"
                            className="h-8 mt-2 ml-2 cursor-pointer cart-icon"
                          >
                            <path d="M12.6684 11.5616C11.5683 11.5616 10.6734 12.4566 10.6734 13.5566C10.6734 14.6567 11.5684 15.5516 12.6684 15.5516C13.7685 15.5516 14.6634 14.6567 14.6634 13.5566C14.6634 12.4566 13.7685 11.5616 12.6684 11.5616ZM12.6684 14.3546C12.2283 14.3546 11.8704 13.9967 11.8704 13.5566C11.8704 13.1165 12.2283 12.7586 12.6684 12.7586C13.1085 12.7586 13.4664 13.1165 13.4664 13.5566C13.4664 13.9967 13.1085 14.3546 12.6684 14.3546ZM15.8728 3.85103C15.8169 3.77947 15.7454 3.7216 15.6637 3.68181C15.5821 3.64202 15.4924 3.62136 15.4016 3.62141H3.6944L3.15574 1.36764C3.12443 1.23679 3.04997 1.1203 2.94435 1.03694C2.83873 0.95359 2.70812 0.908239 2.57358 0.908203H0.598504C0.267939 0.908172 0 1.17611 0 1.50668C0 1.83724 0.267939 2.10518 0.598504 2.10518H2.10117L4.04631 10.2441C4.07757 10.3749 4.15203 10.4915 4.25765 10.5748C4.36328 10.6582 4.49391 10.7035 4.62846 10.7035H13.9452C14.2204 10.7035 14.4601 10.516 14.5262 10.249L15.9826 4.36372C16.0043 4.27554 16.0057 4.18356 15.9867 4.09474C15.9677 4.00593 15.9287 3.92259 15.8728 3.85103ZM13.477 9.50654H5.10087L3.98046 4.81841H14.6369L13.477 9.50654ZM5.42647 11.5616C4.3264 11.5616 3.43146 12.4566 3.43146 13.5566C3.43146 14.6567 4.32643 15.5516 5.42647 15.5516C6.52651 15.5516 7.42148 14.6567 7.42148 13.5566C7.42148 12.4566 6.52651 11.5616 5.42647 11.5616ZM5.42647 14.3546C4.98637 14.3546 4.62846 13.9967 4.62846 13.5566C4.62846 13.1165 4.98637 12.7586 5.42647 12.7586C5.86657 12.7586 6.22447 13.1165 6.22447 13.5566C6.22447 13.9967 5.86657 14.3546 5.42647 14.3546Z" />
                          </svg>
                        </div>
                      </button>
                      <button
                        className="favorite-button border bg-white border-moderate-color cursor-pointer rounded-full h-10 w-10"
                        onClick={toggleFavorite}
                      >
                        <div className="flex items-center justify-center">
                          {/* Check if the product is in favorites and render the appropriate icon */}
                          {isInFavorites(product._id) ? (
                            <svg
                              className="w-6 h-6 text-primary-color"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path d="M3.172 5.172a4 4 0 015.656 0L10 6.344l1.172-1.172a4 4 0 015.656 5.656l-1.172 1.172-5.656 5.656-5.656-5.656L3.172 10.83a4 4 0 010-5.656z" />
                            </svg>
                          ) : (
                            <svg
                              className="w-6 h-6 text-primary-color "
                              width="21"
                              height="17"
                              viewBox="0 0 21 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M17.6916 1.76514C20.3283 3.97998 19.9416 7.60107 18.0783 9.53467L11.8908 15.8276C11.5392 16.1792 11.0822 16.3901 10.59 16.3901C10.0627 16.3901 9.60562 16.1792 9.25406 15.8276L3.10172 9.53467C1.20328 7.60107 0.816559 3.97998 3.45328 1.76514C5.49234 0.0424805 8.62125 0.288574 10.59 2.29248C12.5236 0.288574 15.6525 0.0424805 17.6916 1.76514ZM16.8478 8.33936C18.1486 7.03857 18.3947 4.57764 16.6017 3.03076C15.2306 1.90576 13.1212 2.08154 11.8205 3.41748L10.59 4.68311L9.35953 3.41748C8.02359 2.08154 5.91422 1.90576 4.54312 3.06592C2.75015 4.57764 2.99625 7.03857 4.29703 8.33936L10.4494 14.6323C10.5548 14.7026 10.6252 14.7026 10.6955 14.6323L16.8478 8.33936Z"
                                fill="red"
                              />
                            </svg>
                          )}
                        </div>
                      </button>
                    </div>
                  </div>

                  {/* Buy Now Button */}
                  <div className="pt-5">
                    <button
                      onClick={handleBuyNow}
                      className="buy-btn border-none bg-primary-color w-72 h-9 rounded-full text-xl text-white justify-center cursor-pointer"
                    >
                      <div className="flex items-center justify-center text-base">
                        <img
                          src={BuyIcon}
                          alt=""
                          className="h-4 cursor-pointer mr-2"
                        />{" "}
                        Buy Now
                      </div>
                    </button>
                  </div>
                  {/* Share Icons */}
                  <div>
                    <div className="flex gap-4 text-sm font-semibold uppercase pt-5">
                      Share :
                      <img src={FIcon} alt="" className="cursor-pointer" />
                      <img src={TIcon} alt="" className="cursor-pointer" />
                      <img src={InIcon} alt="" className="cursor-pointer" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-view-msgbox flex justify-between gap-3 pt-6 pb-28">
              <div className="product-view-msgbox1">
                <ProductRelated productId={id} />
              </div>
              <div className="product-view-msgbox2">
                <Productinfo productId={id} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
      <div className="md:hidden h-[3.6rem] sm:h-16"></div>

      <div className="fixed z-10 w-full bottom-0">
        <TabletNavbar />
      </div>
      <LoginPopup isOpen={isLoginOpen} onClose={handleCloseLogin} />
    </>
  );
};

export default ProductView;
