// import React, { useContext, useState, useEffect } from 'react';
// import { CartContext } from '../header/CartContext';
// import Header from "../../components/header/header";
// import Navbar from "../../components/navbar/navbar";
// import Footer from '../Footer/Footer';
// import { useNavigate } from 'react-router-dom';
// import LoginPopup from '../Authentication/LoginPopup'; 
// import deleteicon from '../../assets/images/deleteicon.svg';

// import TabletNavbar from '../navbar/TabletNavbar';
// import Products from "../../Model";
// import ProdBanner from '../../assets/images/ProductBanner.svg';
// import { ToastContainer } from 'react-toastify';
// import { DecrementIcon, decrementicon, DeleteIcon, IncrementIcon, incrementicon, TitleBanner } from '../Container/Images';
// import StarRating from '../../StarRating';

// const CartItems = () => {
//   const { cartItems, cartTotal, removeFromCart, updateCartItemQuantity } = useContext(CartContext);
//   const [counts, setCounts] = useState({});
//   const navigate = useNavigate();
//   const [isLoginOpen, setShowLoginPopup] = useState(false);

//   const handleProductClick = (productId) => {
//     navigate(`/product/${productId}`);
//   };

//   useEffect(() => {
//     const initialCounts = {};
//     cartItems.forEach(item => {
//       initialCounts[`${item._id}-${item.variant}`] = item.quantity;
//     });
//     setCounts(initialCounts);
//     window.scrollTo(0, 0);
//   }, [cartItems]);

//   console.log(cartItems)

//   const handleIncrement = (productId, variant) => {
//     const key = `${productId}-${variant}`;
//     const updatedQuantity = (counts[key] || 1) + 1;
//     setCounts(prevCounts => ({
//       ...prevCounts,
//       [key]: updatedQuantity,
//     }));
//     updateCartItemQuantity(productId, variant, updatedQuantity);
//     showPopupMessage(productId, variant, updatedQuantity);
//   };

//   const handleDecrement = (productId, variant) => {
//     const key = `${productId}-${variant}`;
//     const updatedQuantity = Math.max(1, (counts[key] || 1) - 1);
//     setCounts(prevCounts => ({
//       ...prevCounts,
//       [key]: updatedQuantity,
//     }));
//     updateCartItemQuantity(productId, variant, updatedQuantity);
//     showPopupMessage(productId, variant, updatedQuantity);
//   };

//   const calculatePrice = (productId, variant, quantity) => {
//     const product = Products.find(p => p._id === productId);
//     if (product) {
//       const variantDetails = product.variants.find(v => v.name === variant);
//       const price = variantDetails ? parseFloat(variantDetails.price.replace('₹', '')) : 0;
//       return `₹${(price * quantity).toFixed(2)}`;
//     }
//     return '₹0.00';
//   };

//   const showPopupMessage = (productId, variant) => {
//     const product = Products.find(p => p._id === productId);
//     if (product) {
//       const variantPrice = product.variants.find(v => v.name === variant)?.price || 'N/A';
//       // Implement the logic to display a popup message with the variant price
//     }
//   };

//   const isLoggedIn = () => {
//     return false; 
//   };


//   const handleCheckout = () => {
//     const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
  
//     if (isLoggedIn) {
//       navigate('/checkout'); 
//     } else {
//       localStorage.setItem('intendedPath', '/checkout');
//       setShowLoginPopup(true); 
//     }
//   };
  

//   const handleCloseLogin = () => {
//     setShowLoginPopup(false);
//   };



//   return (
//     <>
//        <ToastContainer />
//       <Header />
//       <Navbar />

//       <div
//         className="relative flex-col justify-center"
//         style={{
//           backgroundImage: `url(${TitleBanner})`,
//           minHeight: '250px',
//           backgroundPosition: 'center',
//         }}
//       >
//         <div className="absolute inset-0 bg-black opacity-50"></div>
//         <div className="relative flex flex-col gap-3 items-center justify-center h-full pt-16">
//           <div className="text-3xl font-poppins text-white font-bold">Cart Items</div>
//           <div className="text-base font-poppins text-white">Home / Cart Items</div>
//         </div>
//       </div>

//       <div className="container flex justify-center mx-auto p-4">
//         <div className="p-4 overflow-x-auto">
//           <table className="w-full bg-white rounded py-4 px-10">
//             <thead className=" text-black uppercase text-sm leading-normal">
//               <h3 className='text-base ml-6 bg-white mb-2 whitespace-nowrap'>Cart product list</h3>
//               <tr className='bg-slate-100'>
//                 <th className="py-3 px-6 text-left">Product</th>
//                 <th className="py-3 px-6 text-left">Variant</th>
//                 <th className="py-3 px-6 text-center">Qty</th>
//                 <th className="py-3 px-6 text-center whitespace-nowrap">Total Price</th>
//                 <th className="py-3 px-6 text-center">Action</th>
//               </tr>
//             </thead>
//             <tbody>
//               {cartItems.length === 0 ? (
//                 <tr>
//                   <td colSpan="5" className="py-4 px-6 text-center">Your cart is empty.</td>
//                 </tr>
//               ) : (
//                 cartItems.map((item) => (
//                   <tr key={`${item._id}-${item.variant}`} className="border-b border-gray-200">
//                     <td className="py-3 px-6 text-left cursor-pointer">
//                       <div className="flex items-center">
//                         <img src={item.image[0]} alt={item.title.en} className="w-16 h-16 object-cover"  onClick={() => handleProductClick(item._id)} />
//                         <div className='flex-col items-center'>
//                           <div className=""  onClick={() => handleProductClick(item._id)}>{item.title.en}</div>
//                             <div className=" mt-2 mr-56"><StarRating rating={item.rating}/></div>
//                         </div>

//                       </div>
//                     </td>
//                     <td className="py-3 px-6 text-left whitespace-nowrap">
//                       {item.variant && item.variant.variantName
//                         ? typeof item.variant.variantName === 'object'
//                           ? item.variant.variantName.en
//                           : item.variant.variantName
//                         : 'No Variant'}
//                     </td>

//                     <td className="py-3 px-6 text-center">
//                       <div className="flex items-center justify-center bg-white border-slate-200 gap-2 border-solid p-1 border-y-2 rounded-full">
//                         <button
//                         onClick={() => handleDecrement(item._id, item.variant)}
//                         aria-label="Decrement quantity"

                        
//                         className="border-none cursor-pointer bg-gray-300 rounded-full h-6 w-6"
//                       >
//                           <img src={DecrementIcon} alt="Decrement" className="w-4 h-4" />
//                         </button>
//                         <span>{counts[`${item._id}-${item.variant}`] || item.quantity}</span>
//                         <button
//                         onClick={() => handleIncrement(item._id, item.variant)}
//                         aria-label="Increment quantity"
//                         className="border-none cursor-pointer bg-gray-300 rounded-full h-6 w-6"
//                       >
//                           <img src={IncrementIcon} alt="Increment" className="w-4 h-4" />
//                         </button>
//                       </div>
//                     </td>
//                     <td className="py-3 px-6 text-center">
//                     ₹{(parseFloat(item.price.replace('₹', '')) * item.quantity).toFixed(2)}
//                     </td>
//                     <td className="py-3 px-6 text-center">
//                       <button className='border-none bg-white' onClick={() => removeFromCart(item._id,item.variant)}>
//                       <img src={DeleteIcon} alt="Delete" className="cursor-pointer w-5 h-5" />
//                       </button>
//                     </td>
//                   </tr>
//                 ))
//               )}
//             <tr className='bg-slate-100 h-10'>
//               <td className='pl-6'>
//                 <div className="text-lg font-semibold">Total Price:</div>
//               </td>
//               <td></td>
//               <td></td>
//               <td></td>
//               <td>
//               <div className="text-lg font-semibold ml-6">₹{cartTotal}</div>
//               </td>
//             </tr>
//                  <tr>
//                   <td colSpan="5" className="py-4 px-6">
//                     <div className="flex justify-between">
//                       <button
//                         className="bg-moderate-color  border-none cursor-pointer text-white px-4 py-2 rounded-md hover:bg-primary-color"
//                         onClick={() => navigate("/")}
//                       >
//                         Continue Shopping
//                       </button>
//                       <button
//                         onClick={handleCheckout}
//                         className="bg-moderate-color  border-none cursor-pointer text-white px-4 py-2 rounded-md hover:bg-primary-color"
//                       >
//                         Proceed to Checkout
//                       </button>
//                     </div>
//                   </td>
//                 </tr>
//             </tbody>
//           </table>
        
//         </div>
//       </div>

//       <LoginPopup isOpen={isLoginOpen} onClose={handleCloseLogin} />

//       <Footer />

//       <div className="md:hidden h-[3.6rem] sm:h-16">

// </div>
//       <div className='fixed z-10 w-full bottom-0'>
//         <TabletNavbar />
//       </div>
//     </>
//   );
// };

// export default CartItems;



import React, { useContext, useState, useEffect } from 'react';
import { CartContext } from '../header/CartContext';
import Header from "../../components/header/header";
import Navbar from "../../components/navbar/navbar";
import Footer from '../Footer/Footer';
import { useNavigate } from 'react-router-dom';
import LoginPopup from '../Authentication/LoginPopup'; 
import deleteicon from '../../assets/images/deleteicon.svg';

import TabletNavbar from '../navbar/TabletNavbar';
import Products from "../../Model";
import ProdBanner from '../../assets/images/ProductBanner.svg';
import { ToastContainer } from 'react-toastify';
import { DecrementIcon, decrementicon, DeleteIcon, IncrementIcon, incrementicon, TitleBanner } from '../Container/Images';
import StarRating from '../../StarRating';
import { Helmet } from 'react-helmet';

const CartItems = () => {
  const { cartItems, cartTotal, removeFromCart, updateCartItemQuantity } = useContext(CartContext);
  const [counts, setCounts] = useState({});
  const navigate = useNavigate();
  const [isLoginOpen, setShowLoginPopup] = useState(false);


  const handleProductClick = (productId,productName) => {
    window.scrollTo(0, 0);
    navigate(`/product/${productName}/${productId}`);
  };

  useEffect(() => {
    const initialCounts = {};
    cartItems.forEach(item => {
      initialCounts[`${item._id}-${item.variant}`] = item.quantity;
    });
    setCounts(initialCounts);
    window.scrollTo(0, 0);
  }, [cartItems]);

  console.log(cartItems)

  const handleIncrement = (productId, variant) => {
    const key = `${productId}-${variant}`;
    const updatedQuantity = (counts[key] || 1) + 1;
    setCounts(prevCounts => ({
      ...prevCounts,
      [key]: updatedQuantity,
    }));
    updateCartItemQuantity(productId, variant, updatedQuantity);
    showPopupMessage(productId, variant, updatedQuantity);
  };

  const handleDecrement = (productId, variant) => {
    const key = `${productId}-${variant}`;
    const updatedQuantity = Math.max(1, (counts[key] || 1) - 1);
    setCounts(prevCounts => ({
      ...prevCounts,
      [key]: updatedQuantity,
    }));
    updateCartItemQuantity(productId, variant, updatedQuantity);
    showPopupMessage(productId, variant, updatedQuantity);
  };

  const calculatePrice = (productId, variant, quantity) => {
    const product = Products.find(p => p._id === productId);
    if (product) {
      const variantDetails = product.variants.find(v => v.name === variant);
      const price = variantDetails ? parseFloat(variantDetails.price.replace('₹', '')) : 0;
      return `₹${(price * quantity).toFixed(2)}`;
    }
    return '₹0.00';
  };

  const showPopupMessage = (productId, variant) => {
    const product = Products.find(p => p._id === productId);
    if (product) {
      const variantPrice = product.variants.find(v => v.name === variant)?.price || 'N/A';
      // Implement the logic to display a popup message with the variant price
    }
  };

  const isLoggedIn = () => {
    return false; 
  };


  const handleCheckout = () => {
    const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
  
    if (isLoggedIn) {
      navigate('/checkout'); 
    } else {
      localStorage.setItem('intendedPath', '/checkout');
      setShowLoginPopup(true); 
    }
  };
  

  const handleCloseLogin = () => {
    setShowLoginPopup(false);
  };



  return (
    <>
       <ToastContainer />
       <Helmet>
          <title>{"Cart Items"}</title>
          </Helmet>
      <Header />
      <Navbar />

      <div
        className="relative flex-col justify-center"
        style={{
          backgroundImage: `url(${TitleBanner})`,
          minHeight: '200px',
          backgroundPosition: 'center',
        }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative flex flex-col gap-3 items-center justify-center h-full pt-16">
          <div className="text-3xl font-poppins text-white font-bold">Cart Items</div>
          <div className="text-base font-poppins text-white">Home / Cart Items</div>
        </div>
      </div>

      <div className="container flex justify-center mx-auto p-4">
        <div className="p-4 overflow-x-auto">
          <table className="w-full bg-white rounded py-4 px-10">
            <thead className=" text-black uppercase text-sm leading-normal">
              <h3 className='text-base ml-6 bg-white mb-2 whitespace-nowrap'>Cart product list</h3>
              <tr className='bg-slate-100'>
                <th className="py-3 px-6 text-left">Product</th>
                <th className="py-3 px-6 text-left">Variant</th>
                <th className="py-3 px-6 text-center">Qty</th>
                <th className="py-3 px-6 text-center whitespace-nowrap">Total Price</th>
                <th className="py-3 px-6 text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {cartItems.length === 0 ? (
                <tr>
                  <td colSpan="5" className="py-4 px-6 text-center">Your cart is empty.</td>
                </tr>
              ) : (
                cartItems.map((item) => (
                  <tr key={`${item._id}-${item.variant}`} className="border-b border-gray-200">
                    <td className="py-3 px-6 text-left cursor-pointer">
                      <div className="flex items-center">
                        <img src={item.image[0]} alt={item.title.en} className="w-16 h-16 object-cover"  onClick={() => handleProductClick(item._id)} />
                        <div className='flex-col items-center'>
                          <div className=""  onClick={() => handleProductClick(item._id,item.slug)}>{item.title.en}</div>
                            <div className=" mt-2 mr-56"><StarRating rating={item.rating}/></div>
                        </div>

                      </div>
                    </td>
                    <td className="py-3 px-6 text-left whitespace-nowrap">
                      {item.variant && item.variant.variantName
                        ? typeof item.variant.variantName === 'object'
                          ? item.variant.variantName.en
                          : item.variant.variantName
                        : 'No Variant'}
                    </td>

                    <td className="py-3 px-6 text-center">
                      <div className="flex items-center justify-center bg-white border-slate-200 gap-2 border-solid p-1 border-y-2 rounded-full">
                        <button
                        onClick={() => handleDecrement(item._id, item.variant)}
                        aria-label="Decrement quantity"

                        
                        className="border-none cursor-pointer bg-gray-300 rounded-full h-6 w-6"
                      >
                          <img src={DecrementIcon} alt="Decrement" className="w-4 h-4" />
                        </button>
                        <span>{counts[`${item._id}-${item.variant}`] || item.quantity}</span>
                        <button
                        onClick={() => handleIncrement(item._id, item.variant)}
                        aria-label="Increment quantity"
                        className="border-none cursor-pointer bg-gray-300 rounded-full h-6 w-6"
                      >
                          <img src={IncrementIcon} alt="Increment" className="w-4 h-4" />
                        </button>
                      </div>
                    </td>
                    <td className="py-3 px-6 text-center">
                    ₹{(parseFloat(item.price.replace('₹', '')) * item.quantity).toFixed(2)}
                    </td>
                    <td className="py-3 px-6 text-center">
                      <button className='border-none bg-white' onClick={() => removeFromCart(item._id,item.variant)}>
                      <img src={DeleteIcon} alt="Delete" className="cursor-pointer w-5 h-5" />
                      </button>
                    </td>
                  </tr>
                ))
              )}
            <tr className='bg-slate-100 h-10'>
              <td className='pl-6'>
                <div className="text-lg font-semibold">Total Price:</div>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td>
              <div className="text-lg font-semibold ml-6">₹{cartTotal}</div>
              </td>
            </tr>
                 <tr>
                  <td colSpan="5" className="py-4 px-6">
                    <div className="flex justify-between">
                      <button
                        className="bg-primary-color hover:bg-secondary-color border-none cursor-pointer text-white px-4 py-2 rounded-md "
                        onClick={() => navigate("/")}
                      >
                        Continue Shopping
                      </button>
                      <button
                        onClick={handleCheckout}
                        className="bg-primary-color hover:bg-secondary-color  border-none cursor-pointer text-white px-4 py-2 rounded-md"
                      >
                        Proceed to Checkout
                      </button>
                    </div>
                  </td>
                </tr>
            </tbody>
          </table>
        
        </div>
      </div>

      <LoginPopup isOpen={isLoginOpen} onClose={handleCloseLogin} />

      <Footer />

      <div className="md:hidden h-[3.6rem] sm:h-16">

</div>
      <div className='fixed z-10 w-full bottom-0'>
        <TabletNavbar />
      </div>
    </>
  );
};

export default CartItems;