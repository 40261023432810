// import React, { useState, useEffect } from "react";
// import { useNavigate, useLocation } from "react-router-dom";
// import Filter from "./Filter";
// import ProductAll from "./ProductAll";
// import Footer from "../../components/Footer/Footer";
// import ProductBanner from "../Product/ProductBanner";
// import Header from "../header/header";
// import Navbar from "../navbar/navbar";
// import TabletNavbar from "../navbar/TabletNavbar";
// import WhatsappButton from "../contact/WhatsappButton";
// import Phonefilter from "./Phonefilter";
// import Brandlist from "./Brandlist";
// import axios from "axios";
// import { baseURL } from "../api";

// const ProductShow = () => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [products, setProducts] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState("");
//   const [selectedCategoryId, setSelectedCategoryId] = useState("");
//   const [showSection, setShowSection] = useState(false);
//   const [section, setSection] = useState("");
//   const [selectedTitle, setSelectedTitle] = useState("All Products");
//   const [filters, setFilters] = useState({
//     category: [],
//     categoryId: [],
//     minPrice: 0,
//     maxPrice: 20000,
//     brand: [],
//   });
//   const [isPhoneFilterOpen, setIsPhoneFilterOpen] = useState(false);

//   useEffect(() => {
//     if (location.state && location.state.section) {
//       setShowSection(true);
//       setSection(location.state.section);
//     }

//     if (location.state && location.state.openPhoneFilter) {
//       setIsPhoneFilterOpen(true);
//     }

//     if (location.state && location.state.category) {
//       setFilters((prevFilters) => ({
//         ...prevFilters,
//         category: [location.state.category],
//         categoryId: [location.state.id._id],
//       }));

//       setSelectedCategory(location.state.category);
//       setSelectedCategoryId(location.state.id._id);
//     }

//     if (location.state && location.state.brand) {
//       setFilters((prevFilters) => ({
//         ...prevFilters,
//         brand: [location.state.brand],
//       }));
//     }

//     if (location.state && location.state.category) {
//       axios
//         .get(
//           `${baseURL}/products?price=published&category=` +
//             location.state.id._id
//         )
//         .then((response) => {
//           if (response.data && response.data.products) {
//             setProducts(response.data.products);
//           } else {
//             console.error("Unexpected response format", response);
//           }
//         })
//         .catch((error) => {
//           console.error("Error fetching products:", error);
//         });
//     } else if (location.state && location.state.section) {
//       if (location.state.section == "Monthly Sale Products") {
//         setSelectedTitle(location.state.title);
//       }
//       if (location.state.section == "New Arrival") {
//         axios
//           .get(`${baseURL}/products?price=published`)
//           .then((response) => {
//             if (response.data && response.data.products) {
//               const allProducts = response.data.products;
//               const recentProducts = allProducts.filter((product) => {
//                 const productDate = new Date(product.updatedAt);
//                 console.log(productDate);
//                 const tenDaysAgo = new Date();
//                 tenDaysAgo.setDate(tenDaysAgo.getDate() - 10);
//                 return productDate >= tenDaysAgo;
//               });
//               setProducts(recentProducts);
//               // setFilteredProducts(recentProducts);
//             } else {
//               console.error("Unexpected response format", response);
//             }
//           })
//           .catch((error) => {
//             console.error("Error fetching products:", error);
//           });
//       } else {
//         axios
//           .get(
//             `${baseURL}/products/section?price=published&section=` +
//               location.state.section
//           )
//           .then((response) => {
//             if (response.data && response.data.products) {
//               console.log(response.data.products);
//               setProducts(response.data.products);
//             } else {
//               console.error("Unexpected response format", response);
//             }
//           })
//           .catch((error) => {
//             console.error("Error fetching products:", error);
//           });
//       }
//     } else {
//       axios
//         .get(`${baseURL}/products?price=published`)
//         .then((response) => {
//           if (response.data && response.data.products) {
//             setProducts(response.data.products);
//           } else {
//             console.error("Unexpected response format", response);
//           }
//         })
//         .catch((error) => {
//           console.error("Error fetching products:", error);
//         });
//     }
//   }, [location.state, ""]);

//   const handleFilterChange = (newFilters) => {
//     console.log(newFilters);
//     setFilters(newFilters);
//   };
//   const handlePhoneFilterChange = (newFilters) => {
//     console.log(newFilters);
//     setFilters(newFilters);
//   };

//   const handleShowPhoneFilter = () => {
//     setIsPhoneFilterOpen(true);
//   };

//   const handleHidePhoneFilter = () => {
//     setIsPhoneFilterOpen(false);
//   };

//   // Handle brand selection and navigate to the product page

//   // const handleBrandSelect = (brand) => {
//   //   console.log('Selected Brand:', brand);
//   //   setFilters((prevFilters) => ({
//   //     ...prevFilters,
//   //     brand: [brand],
//   //   }));
//   //   navigate('/product', { state: { brand } });
//   // };

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   return (
//     <>
//       <div className="fixed z-10 w-full top-0">
//         <Header className="bg-white shadow-lg sm:px-4 md:px-6 lg:px-8 xl:px-10" />
//         <Navbar className="bg-gray-800 text-white py-2 sm:py-3 md:py-4 lg:py-5 xl:py-6" />
//       </div>
//       <div className="pt-24">
//         {!showSection ? (
//           <ProductBanner
//             selectedCategory={filters.category[0]}
//             selectedBrand={filters.brand[0]}
//           />
//         ) : (
//           <ProductBanner selectedSection={section} />
//         )}
//         <div className="productall-full-container relative flex justify-between bg-slate-100">
//           {/* Filter */}
//           {!showSection ? (
//             <div>
//               <Filter
//                 productsProp={products}
//                 onFilterChange={handleFilterChange}
//                 selectedCategory={selectedCategory}
//                 selectedCategoryId={selectedCategoryId}
//                 selectedBrand={filters.brand[0]}
//               />
//             </div>
//           ) : (
//                         <div>
//               <Filter
//                 productsProp={products}
//                 onFilterChange={handleFilterChange}
//                 selectedCategory={selectedCategory}
//                 selectedCategoryId={selectedCategoryId}
//                 selectedBrand={filters.brand[0]}
//               />
//             </div>
//           )}

//           {/* All Products */}
//           <div>
//             <ProductAll
//               filters={filters}
//               productsProp={products}
//               selectedSection={section}
//               isOpen={isPhoneFilterOpen}
//               selectedTitle={selectedTitle}
//             />
//           </div>
//         </div>
//         <WhatsappButton />
//         <div className="pt-5">
//           <Footer />
//         </div>
//       </div>

//       {/* Phonefilter Popup */}
//       {!showSection ? (
//         <Phonefilter
//           productsProp={products}
//           isOpen={isPhoneFilterOpen}
//           onClose={handleHidePhoneFilter}
//           onFilterChange={handlePhoneFilterChange}
//           selectedCategory={filters.category[0]}
//           selectedCategoryId={filters.categoryId[0]}
//           selectedBrand={filters.brand[0]}
//         />
//       ) : (
//         ""
//       )}

// <div className="md:hidden h-[3.6rem] sm:h-16">

// </div>
//       <div className="fixed z-10 w-full bottom-0">
//         <TabletNavbar />
//       </div>
//     </>
//   );
// };

// export default ProductShow;

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Filter from "./Filter";
import ProductAll from "./ProductAll";
import Footer from "../../components/Footer/Footer";
import ProductBanner from "../Product/ProductBanner";
import Header from "../header/header";
import Navbar from "../navbar/navbar";
import TabletNavbar from "../navbar/TabletNavbar";
import WhatsappButton from "../contact/WhatsappButton";
import Phonefilter from "./Phonefilter";
import Brandlist from "./Brandlist";
import axios from "axios";
import { baseURL } from "../api";
import { Helmet } from "react-helmet";
import MateTagsService from "../Service/MateTagsService";

const ProductShow = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [products, setProducts] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [showSection, setShowSection] = useState(false);
  const [section, setSection] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("All Products");
  const [filters, setFilters] = useState({
    category: [],
    categoryId: [],
    minPrice: 0,
    maxPrice: 20000,
    brand: [],
  });
  const [isPhoneFilterOpen, setIsPhoneFilterOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const productsPerPage = 12;

  const [tags, setTags] = useState();
  useEffect(() => {
    const page = "products all";

    const getTags = async () => {
      try {
        const response = await MateTagsService.getMateTagsPage({ page });

        setTags(response.data);

        console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    getTags();
  }, []);

  useEffect(() => {
    if (location.state && location.state.section) {
      setShowSection(true);
      setSection(location.state.section);
    }

    if (location.state && location.state.openPhoneFilter) {
      setIsPhoneFilterOpen(true);
    }

    if (location.state && location.state.category) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        category: [location.state.category],
        categoryId: [location.state.id._id],
      }));

      setSelectedCategory(location.state.category);
      setSelectedCategoryId(location.state.id._id);
    }

    if (location.state && location.state.brand) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        brand: [location.state.brand],
      }));
    }

    if (location.state && location.state.category) {
      axios
      .get(
        `${baseURL}/products?price=published&category=${location.state.id._id}&limit=${productsPerPage}&page=${currentPage}`
      )
        .then((response) => {
          if (response.data && response.data.products) {
            setProducts(response.data.products);
            setTotalProducts(response.data.totalDoc);
            setTotalPages(Math.ceil(response.data.totalDoc / productsPerPage));
          } else {
            console.error("Unexpected response format", response);
          }
        })
        .catch((error) => {
          console.error("Error fetching products:", error);
        });
    } else if (location.state && location.state.section) {
      if (location.state.section == "Monthly Sale Products") {
        setSelectedTitle(location.state.title);
      }
      if (location.state.section == "New Arrival") {
        axios
          .get(
            `${baseURL}/products?price=published&limit=${productsPerPage}&page=${currentPage}`
          )
          .then((response) => {
            if (response.data && response.data.products) {
              const allProducts = response.data.products;

              const recentProducts = allProducts.filter((product) => {
                const productDate = new Date(product.updatedAt);
                console.log(productDate);
                const tenDaysAgo = new Date();
                tenDaysAgo.setDate(tenDaysAgo.getDate() - 10);
                return productDate >= tenDaysAgo;
              });
              setProducts(recentProducts);
              setTotalProducts(response.data.totalDoc);
              setTotalPages(
                Math.ceil(response.data.totalDoc / productsPerPage)
              );
              // setFilteredProducts(recentProducts);
            } else {
              console.error("Unexpected response format", response);
            }
          })
          .catch((error) => {
            console.error("Error fetching products:", error);
          });
      } else {
        axios
          .get(
            `${baseURL}/products/section?price=published&limit=${productsPerPage}&page=${currentPage}&section=` +
              location.state.section
          )
          .then((response) => {
            if (response.data && response.data.products) {
              console.log(response.data.products);
              setProducts(response.data.products);
              setTotalProducts(response.data.totalDoc);
              setTotalPages(
                Math.ceil(response.data.totalDoc / productsPerPage)
              );
            } else {
              console.error("Unexpected response format", response);
            }
          })
          .catch((error) => {
            console.error("Error fetching products:", error);
          });
      }
    } else {
      axios
        .get(
          `${baseURL}/products?price=published&limit=${productsPerPage}&page=${currentPage}`
        )
        .then((response) => {
          if (response.data && response.data.products) {
            setProducts(response.data.products);
            setTotalProducts(response.data.totalDoc);
            console.log(response.data.products);
            setTotalPages(Math.ceil(response.data.totalDoc / productsPerPage));
          } else {
            console.error("Unexpected response format", response);
          }
        })
        .catch((error) => {
          console.error("Error fetching products:", error);
        });
    }
  }, [currentPage, location.state, ""]);

  const handleFilterChange = (newFilters) => {
    console.log(newFilters);
    setFilters(newFilters);
  };
  const handlePhoneFilterChange = (newFilters) => {
    console.log(newFilters);
    setFilters(newFilters);
  };

  const handleShowPhoneFilter = () => {
    setIsPhoneFilterOpen(true);
  };

  const handleHidePhoneFilter = () => {
    setIsPhoneFilterOpen(false);
  };

  // Handle brand selection and navigate to the product page

  // const handleBrandSelect = (brand) => {
  //   console.log('Selected Brand:', brand);
  //   setFilters((prevFilters) => ({
  //     ...prevFilters,
  //     brand: [brand],
  //   }));
  //   navigate('/product', { state: { brand } });
  // };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <Helmet>
        {/* Basic SEO Meta Tags */}
        <title>{tags ? tags.title : "Default Title"}</title>
        <meta
          name="description"
          content={tags ? tags.description : "Default description"}
        />
        <meta
          name="keywords"
          content={tags ? tags.keywords : "default, keywords"}
        />
        <link rel="canonical" href={tags ? tags.canonical : "/"} />

        {/* Open Graph Meta Tags (for Social Media) */}
        <meta
          property="og:title"
          content={tags ? tags.title : "Default Title"}
        />
        <meta
          property="og:description"
          content={tags ? tags.description : "Default description"}
        />
        <meta property="og:url" content={tags ? tags.canonical : "/"} />

        {/* Twitter Card Meta Tags */}
        <meta
          name="twitter:title"
          content={tags ? tags.title : "Default Title"}
        />
        <meta
          name="twitter:description"
          content={tags ? tags.description : "Default description"}
        />
      </Helmet>
      <div className="fixed z-10 w-full top-0">
        <Header className="bg-white shadow-lg sm:px-4 md:px-6 lg:px-8 xl:px-10" />
        <Navbar className="bg-gray-800 text-white py-2 sm:py-3 md:py-4 lg:py-5 xl:py-6" />
      </div>
      <div className="pt-24">
        {!showSection ? (
          <ProductBanner
            selectedCategory={filters.category[0]}
            selectedBrand={filters.brand[0]}
          />
        ) : (
          <ProductBanner selectedSection={section} />
        )}
        <div className="productall-full-container relative flex justify-between bg-slate-100">
          {/* Filter */}
          {!showSection ? (
            <div>
              <Filter
                productsProp={products}
                onFilterChange={handleFilterChange}
                selectedCategory={selectedCategory}
                selectedCategoryId={selectedCategoryId}
                selectedBrand={filters.brand[0]}
              />
            </div>
          ) : (
            <div>
              <Filter
                productsProp={products}
                onFilterChange={handleFilterChange}
                selectedCategory={selectedCategory}
                selectedCategoryId={selectedCategoryId}
                selectedBrand={filters.brand[0]}
              />
            </div>
          )}

          {/* All Products */}
          <div>
            <ProductAll
              filters={filters}
              productsProp={products}
              selectedSection={section}
              isOpen={isPhoneFilterOpen}
              selectedTitle={selectedTitle}
              currentPage={currentPage}
              totalPages={totalPages}
              totalProducts={totalProducts}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
        <WhatsappButton />
        <div className="pt-5">
          <Footer />
        </div>
      </div>

      {/* Phonefilter Popup */}
      <Phonefilter
        productsProp={products}
        isOpen={isPhoneFilterOpen}
        onClose={handleHidePhoneFilter}
        onFilterChange={handlePhoneFilterChange}
        selectedCategory={filters.category[0]}
        selectedCategoryId={filters.categoryId[0]}
        selectedBrand={filters.brand[0]}
      />

      <div className="md:hidden h-[3.6rem] sm:h-16"></div>
      <div className="fixed z-10 w-full bottom-0">
        <TabletNavbar />
      </div>
    </>
  );
};

export default ProductShow;
