import React, { useEffect, useState } from "react";
import axios from "axios";
import Dogflex from "../../assets/images/Dogflex.png";
import Backcover from "../../assets/images/Backcover.svg";
import { useNavigate } from "react-router-dom";
import MonthlyService from "../Service/MonthlyService";

const Month = () => {
  const [saleData, setSaleData] = useState(null);
  const saleId = "66cd96ac0d6d2cc71bda9c64";
  const navigate = useNavigate();
  useEffect(() => {
    // axios
      // .get(`https://nile-and-mile-backend-5htm6l5x7a-uc.a.run.app/api/monthly/${saleId}`)
      MonthlyService.getMonthlySale(saleId)
      .then((response) => {
        if (response.data) {
          setSaleData(response.data);
        } else {
          console.error("Unexpected response format", response);
        }
      })
      .catch((error) => {
        console.error("Error fetching sale data:", error);
      });
  }, []);

  const handleView = () => {
    navigate("/product", {
      state: { section: "Monthly Sale Products", title: saleData?.title },
    });
  };

  return (
    <div
      className="month-container flex justify-items-center h-80 px-16"
      style={{ backgroundImage: `url(${Backcover})` }}
    >
      <div className="month-text mt-12">
        {saleData ? (
          <>
            <h1 className="text-[17px] uppercase  mt-2 font-semibold text-secondary-color">
              {saleData.title}
            </h1>
            <h2 className="text-black text-3xl font-bold mt-3">
              {saleData.subtitle}
            </h2>
            <p className="text-sm mt-3">{saleData.description}</p>
            <button
              className="month-btn mt-8 relative border-none h-10 rounded-3xl px-8 py-1 text-sm font-bold text-white bg-primary-color cursor-pointer overflow-hidden transition-all duration-300 hover:text-deep-color hover:bg-moderate-color"
              onClick={() => handleView()}
            >
              View
            </button>
          </>
        ) : (
          <p>Loading...</p>
        )}
      </div>
      <div className="month-img ml-2">
        <img
          src={saleData ? saleData.image : Dogflex}
          alt="Sale"
          className="w-full h-80 ml-7"
        />
      </div>
    </div>
  );
};

export default Month;
