import React, { useEffect, useState } from "react";
import Product1 from "../../assets/images/product1.svg";
import Product2 from "../../assets/images/product2.svg";
import Header from "../header/header";
import Navbar from "../navbar/navbar";
import ProdBanner from "../../assets/images/ProductBanner.svg";
import Footer from "../Footer/Footer";
import TabletNavbar from "../navbar/TabletNavbar";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { TitleBanner } from "../Container/Images";
import ProfileDashBordService from "../Service/ProfileDashBordService";
import AttributesService from "../Service/AttributesService";

function OrderDetails() {
  const { state } = useLocation();
  const { orderId } = state || {};
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [variantData, setVariantData] = useState([]);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const attributesResponse = await AttributesService.fetchAllAttribute()
        setVariantData(attributesResponse.data);

        const response = await ProfileDashBordService.fetchProfileOrder(orderId)

        console.log(response.data);

        if (Array.isArray(attributesResponse.data)) {
          const updatedCart = response.data.cart.map((item) => {
            if (item.variant && Array.isArray(attributesResponse.data)) {
              attributesResponse.data.forEach((data) => {
                data.variants.forEach((id) => {
                  if (id._id === item.variant[Object.keys(item.variant)[0]]) {
                    item.variant["variantName"] = id.name.en;
                  }
                });
              });
            }
            return item;
          });

          response.data.cart = updatedCart;
        } else {
          console.error(
            "variantData is not an array:",
            attributesResponse.data
          );
        }

        setOrder(response.data);
      } catch (error) {
        setError("Failed to fetch order details");
      } finally {
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [orderId]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

    return (
      <div className="flex justify-center mt-2">
        {[...Array(fullStars)].map((_, index) => (
          <svg
            key={`full-${index}`}
            className="w-4 h-4 text-yellow-500"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
          </svg>
        ))}
        {halfStar && (
          <svg
            key="half"
            className="w-4 h-4 text-yellow-500"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <defs>
              <linearGradient id="half-star-gradient">
                <stop offset="50%" stopColor="currentColor" />
                <stop offset="50%" stopColor="gray" stopOpacity="1" />
              </linearGradient>
            </defs>
            <path
              fill="url(#half-star-gradient)"
              d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"
            />
          </svg>
        )}
        {[...Array(emptyStars)].map((_, index) => (
          <svg
            key={`empty-${index}`}
            className="w-4 h-4 text-gray-400"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fill="#D1D5DB"
              d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"
            />
          </svg>
        ))}
      </div>
    );
  };

  const handleProductClick = (productId,productName) => {
    window.scrollTo(0, 0);
    navigate(`/product/${productName}/${productId}`);
  };

  return (
    <>
      <div className="fixed z-10 w-full top-0 ">
        <Header className="bg-white shadow-lg sm:px-4 md:px-6 lg:px-8 xl:px-10" />
        <Navbar className="bg-gray-800 text-white py-2 sm:py-3 md:py-4 lg:py-5 xl:py-6" />
      </div>

      <div className="pt-24 ">
        <div
          className="flex-col justify-center relative"
          style={{
            backgroundImage: `url(${TitleBanner})`,
            minHeight: "250px",
            backgroundPosition: "center",
          }}
        >
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="relative flex flex-col gap-3 items-center justify-center h-full pt-28">
            <div className="text-3xl font-poppins text-white font-bold">
              Order Details
            </div>
            <div className="text-base font-poppins text-white">
              Home / Order Details
            </div>
          </div>
        </div>

        <div className="mx-16">
          <div className="responsive-table-container overflow-auto justify-center w-[100%] max-full bg-white my-8 p-4">
            <h2 className="text-lg font-semibold mb-4">Order Details</h2>
            <hr className="border-t-2 border-dashed border-0 border-slate-200 mb-5" />

            <table className="w-full border-collapse border">
              <thead>
                <tr className="bg-slate-100 whitespace-nowrap">
                  <th className="border border-white p-2">Product</th>
                  <th className="border border-white p-2">Quantity</th>
                  <th className="border border-white p-2">Variant</th>
                  <th className="border border-white p-2">Original Price</th>
                  <th className="border border-white p-2">Discount</th>
                  <th className="border border-white p-2">Total Price</th>
                  <th className="border border-white p-2">Status</th>
                </tr>
              </thead>
              <tbody>
                {order?.cart.map((item) => (
                  <tr key={item._id}>
                    <td
                      className="border border-white p-2 flex items-center space-x-2 text-sm cursor-pointer"
                      onClick={() => handleProductClick(item._id,item.slug)}
                    >
                      <img
                        src={item.image[0]}
                        alt={item.title}
                        className="w-10 h-10"
                      />
                      <div className="flex flex-col justify-start">
                        <div>{item.title}</div>
                        <div className="mr-48">{renderStars(item.rating)}</div>
                      </div>
                    </td>
                    <td className="border border-white p-2 text-sm text-center">
                      {item.quantity}
                    </td>
                    <td className="border border-white p-2 text-sm text-center">
                      {item.variant?.variantName || "N/A"}
                    </td>
                    <td className="border border-white p-2 text-sm text-center">
                      {item.price}
                    </td>
                    <td className="border border-white p-2 text-sm text-center">
                      ₹{(item.prices?.discount || 0).toFixed(2)}
                    </td>
                    <td className="border border-white p-2 text-sm text-center">
                    ₹{(parseFloat(item.price.replace('₹', '')) * item.quantity).toFixed(2) || item.totalPrice || "0.00"}
                    </td>
                    <td className="border border-white p-2 text-sm text-center">
                      <span
                        className={`bg-${
                          order.status === "Pending" ? "red" : "green"
                        }-200 text-${
                          order.status === "Pending" ? "red" : "green"
                        }-600 px-2 py-1 rounded-md`}
                      >
                        {order.status}
                      </span>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td className="border border-white p-2 text-left font-semibold">
                    Subtotal
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="border border-white p-2 text-center">
                    ₹{(order.subTotal || 0).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className="border border-white p-2 text-left font-semibold">
                    Discount
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="border border-white p-2 text-center">
                    ₹{(order.discount || 0).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className="border border-white p-2 text-left font-semibold">
                    Shipping Cost
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="border border-white p-2 text-center">
                    ₹{(order.shippingCost || 0).toFixed(2)}
                  </td>
                </tr>
                <tr className="bg-slate-100">
                  <td className="border border-white p-2 text-left font-semibold">
                    Total
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="border border-white p-2 text-center font-semibold">
                    ₹{(order.total || 0).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Footer />
      <div className="md:hidden h-[3.6rem] sm:h-16">

</div>

      <div className='fixed z-10 w-full bottom-0'>
        <TabletNavbar />
      </div>
    </>
  );
}

export default OrderDetails;
