import axios from 'axios'
import React from 'react'
import { baseURL } from '../api'

const MateTagsService =  {

    getMateTagsPage:({page})=>{
        return axios.get(`${baseURL}/mate/${page}`)
    }

}

export default MateTagsService
