// import React, { useContext, useState, useEffect, useMemo } from "react";
// import { useNavigate } from "react-router-dom";
// import { CartContext } from "../header/CartContext";
// import { FavoriteContext } from "../header/FavoriteContext";
// import { ToastContainer } from "react-toastify";

// import FilterIcon from "../../assets/images/phone_icon/menu.svg";
// import StarRating from "../../StarRating";
// import emptyData from "../../assets/images/emptyData.png";
// import "../../App.css";

// const ProductAll = ({ filters, productsProp, selectedTitle }) => {

//   const navigate = useNavigate();

//   const [sortOption, setSortOption] = useState("default");
//   const [currentPage, setCurrentPage] = useState(1);
//   const [products, setProducts] = useState([]);
//   const { section } = Location.state || {};

//   const productsPerPage = 12;

//   useEffect(() => {
//     if (productsProp) {
//       const visibleProducts = productsProp.filter(product => product.status === "show");
//       setProducts(visibleProducts);
//     }
//   }, [productsProp]);


//   const filteredProductsBySection = useMemo(() => {
//     if (!section) return products;
//     return products.filter(product => product.section === section);
//   }, [products, section]);

//   const applyFilters = (products) => {
//     return products.filter(product => {
//       const price = product.prices?.price || 0;
//       const categoryMatch = filters.categoryId.length === 0 || product.categories.some(cat => filters.categoryId.includes(cat._id));
//       const priceMatch = price >= filters.minPrice && price <= filters.maxPrice;
//       const brandMatch = filters.brand.length === 0 || filters.brand.includes(product.brand?.name);
//       return categoryMatch && priceMatch && brandMatch;
//     });
//   };

//   const filteredProducts = useMemo(() => {
//     return applyFilters(filteredProductsBySection);
//   }, [filteredProductsBySection, filters]);

//   const sortedProducts = useMemo(() => {
//     return [...filteredProducts].sort((a, b) => {
//       const priceA = parseFloat(a.prices?.price) || 0;
//       const priceB = parseFloat(b.prices?.price) || 0;
//       const createdAtA = new Date(a.createdAt || "1970-01-01T00:00:00Z");
//       const createdAtB = new Date(b.createdAt || "1970-01-01T00:00:00Z");
  
//       switch (sortOption) {
//         case "low-to-high":
//           return priceA - priceB;
//         case "high-to-low":
//           return priceB - priceA;
//         case "new-product":
//           return createdAtB - createdAtA;
//         case "old-product":
//           return createdAtA - createdAtB;
//         case "default":
//           return (parseFloat(a.prices?.originalPrice) || 0) - (parseFloat(b.prices?.originalPrice) || 0);
//         default:
//           return 0;
//       }
//     });
//   }, [filteredProducts, sortOption]);

//   const indexOfLastProduct = currentPage * productsPerPage;
//   const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
//   const currentProducts = sortedProducts.slice(indexOfFirstProduct, indexOfLastProduct);

//   const handleSortChange = (e) => {
//     setSortOption(e.target.value);
//     setCurrentPage(1);
//   };

//   const paginate = (pageNumber) => {
//     setCurrentPage(pageNumber);
//     window.scrollTo({ top: 0, behavior: "smooth" });
//   };

//   const handleProductClick = (productId) => {
//     window.scrollTo(0, 0);
//     navigate(`/product/${productId}`);
//   };

//   return (
//     <div className="prodctall-main flex flex-col justify-center mt-4">
//       <ToastContainer />
//       <div className="productall-container-filter relative bg-white pt-4 w-full flex justify-between items-center">
//         <div className="flex pb-3">
//           <img src={FilterIcon} alt="Filter Icon" className="w-6 h-6 cursor-pointer md:hidden" onClick={() => navigate("/product", { state: { openPhoneFilter: true } })} />
//           <h2 className="text-lg font-bold ml-2">{selectedTitle}</h2>
//         </div>
//         <div className="flex pb-3 items-center gap-3 mr-4">
//           <label htmlFor="sort" className="mr-2 text-sm whitespace-nowrap">Sort By:</label>
//           <select id="sort" value={sortOption} onChange={handleSortChange} className="border p-1 rounded-full text-sm">
//             <option value="default">SORT BY DEFAULT</option>
//             <option value="low-to-high">Price: Low to High</option>
//             <option value="high-to-low">Price: High to Low</option>
//             <option value="new-product">Newest Products</option>
//             <option value="old-product">Oldest Products</option>
//           </select>
//           <p className="text-sm">
//             Showing {Math.min(indexOfFirstProduct + 1, sortedProducts.length)} - {Math.min(indexOfLastProduct, sortedProducts.length)} of {sortedProducts.length} results
//           </p>
//         </div>
//       </div>

      // <div className="productall-container w-full flex flex-wrap justify-center gap-4 pt-5">
      //   {currentProducts.length > 0 ? currentProducts.map(product => (
      //     <div key={product._id} className="productall-box relative flex flex-col items-center justify-center w-full p-4 border bg-white rounded shadow-md hover:shadow-lg transition-shadow duration-300">
      //       <img src={product.image[0]} alt={product.title.en} className="w-full h-auto object-contain cursor-pointer" onClick={() => handleProductClick(product._id)} />
      //       <h3 className="text-sm font-semibold text-center mb-1  mt-2 line-clamp-2 w-40 " onClick={() => handleProductClick(product._id)}>{product.title.en}</h3>
      //       <StarRating rating={product.rating} />
      //       <div className="flex justify-center items-center gap-2">
      //         <span className="text-lg text-primary-color">₹{product.prices.price}</span>
      //         <span className="text-sm line-through text-gray-600">₹{product.prices.originalPrice}</span>
      //       </div>
      //     </div>
      //   )) : (
      //     <div className="productall-box flex flex-col items-center justify-center w-full p-6 bg-white rounded-lg shadow-md">
      //       <img src={emptyData} alt="No data found" className="w-48 h-48 object-contain mb-4" />
      //       <p className="text-lg font-semibold">No Data Found</p>
      //     </div>
      //   )}
      // </div>

//       {/* Pagination Component */}
      // <div className="flex justify-center mt-16">
      //   <nav>
      //     <ul className="flex list-none">
      //       <li>
      //         <button onClick={() => paginate(currentPage - 1)} className={`px-3 py-2 bg-soft-color hover:bg-primary-color rounded border-none ${currentPage === 1 ? "cursor-not-allowed" : ""}`} disabled={currentPage === 1}>&lt;</button>
      //       </li>
      //       {currentPage > Math.ceil(sortedProducts.length / productsPerPage) - 3 && <li><span className="px-3 py-2 font-bold text-xl">...</span></li>}
      //       {Array.from({ length: Math.ceil(sortedProducts.length / productsPerPage) }, (_, index) => index + 1)
      //         .filter(number => number === 1 || number === Math.ceil(sortedProducts.length / productsPerPage) || Math.abs(number - currentPage) < 3)
      //         .map(number => (
      //           <li key={number} className={`mx-1 ${number === currentPage ? "font-bold" : ""}`}>
      //             <button onClick={() => paginate(number)} className={`px-3 py-2 cursor-pointer ${number === currentPage ? "bg-primary-color font-bold text-[13px]" : "bg-soft-color"} hover:bg-primary-color border-none`}>{number}</button>
      //           </li>
      //         ))}
      //       {currentPage < Math.ceil(sortedProducts.length / productsPerPage) - 2 && <li><span className="px-3 py-2 font-bold text-xl">...</span></li>}
      //       <li>
      //         <button onClick={() => paginate(currentPage + 1)} className={`px-3 py-2 bg-soft-color hover:bg-primary-color rounded border-none ${currentPage === Math.ceil(sortedProducts.length / productsPerPage) ? "cursor-not-allowed" : ""}`} disabled={currentPage === Math.ceil(sortedProducts.length / productsPerPage)}>&gt;</button>
      //       </li>
      //     </ul>
      //   </nav>
      // </div>
//     </div>
//   );
// };

// export default ProductAll;





import React, { useContext, useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../header/CartContext";
import { FavoriteContext } from "../header/FavoriteContext";
import { ToastContainer } from "react-toastify";

import FilterIcon from "../../assets/images/phone_icon/menu.svg";
import StarRating from "../../StarRating";
import emptyData from "../../assets/images/emptyData.png";
import "../../App.css";


const ProductAll = ({ filters, productsProp, selectedTitle, currentPage, totalPages, onPageChange, totalProducts }) => {
  const navigate = useNavigate();

  const [sortOption, setSortOption] = useState("default");
  const [products, setProducts] = useState([]);

  const productsPerPage = 12;

  useEffect(() => {
    if (productsProp) {
      const visibleProducts = productsProp.filter(product => product.status === "show");
      setProducts(visibleProducts);
    }
  }, [productsProp]);

  const applyFilters = (products) => {
    return products.filter(product => {
      const price = product.prices?.price || 0;
      const categoryMatch = filters.categoryId.length === 0 || product.categories.some(cat => filters.categoryId.includes(cat._id));
      const priceMatch = price >= filters.minPrice && price <= filters.maxPrice;
      const brandMatch = filters.brand.length === 0 || filters.brand.includes(product.brand?.name);
      return categoryMatch && priceMatch && brandMatch;
    });
  };

  const filteredProducts = useMemo(() => {
    return applyFilters(products);
  }, [products, filters]);

  // Sorting
  const sortedProducts = useMemo(() => {
    return [...filteredProducts].sort((a, b) => {
      const priceA = parseFloat(a.prices?.price) || 0;
      const priceB = parseFloat(b.prices?.price) || 0;
      const createdAtA = new Date(a.createdAt || "1970-01-01T00:00:00Z");
      const createdAtB = new Date(b.createdAt || "1970-01-01T00:00:00Z");

      switch (sortOption) {
        case "low-to-high":
          return priceA - priceB;
        case "high-to-low":
          return priceB - priceA;
        case "new-product":
          return createdAtB - createdAtA;
        case "old-product":
          return createdAtA - createdAtB;
        case "default":
        default:
          return (parseFloat(a.prices?.originalPrice) || 0) - (parseFloat(b.prices?.originalPrice) || 0);
      }
    });
  }, [filteredProducts, sortOption]);

  const handleSortChange = (e) => {
    setSortOption(e.target.value);
  };


  const handleProductClick = (productId,productName) => {
    window.scrollTo(0, 0);
    navigate(`/product/${productName}/${productId}`);
  };;

  return (
    <div className="prodctall-main flex flex-col justify-center mt-4">
      <ToastContainer />
      <div className="productall-container-filter relative bg-white pt-4 w-full flex justify-between items-center">
        <div className="flex pb-3">
          <img src={FilterIcon} alt="Filter Icon" className="w-6 h-6 cursor-pointer md:hidden" onClick={() => navigate("/product", { state: { openPhoneFilter: true } })} />
          <h2 className="text-lg font-bold ml-2">{selectedTitle}</h2>
        </div>
        <div className="flex pb-3 items-center gap-3 mr-4">
          <label htmlFor="sort" className="mr-2 text-sm whitespace-nowrap">Sort By:</label>
          <select id="sort" value={sortOption} onChange={handleSortChange} className="border p-1 rounded-full text-sm">
            <option value="default">SORT BY DEFAULT</option>
            <option value="low-to-high">Price: Low to High</option>
            <option value="high-to-low">Price: High to Low</option>
            <option value="new-product">Newest Products</option>
            <option value="old-product">Oldest Products</option>
          </select>
          <p className="text-sm">
            Showing {(currentPage - 1) * productsPerPage + 1} - {Math.min(totalProducts, currentPage * productsPerPage)} of {totalProducts} results
          </p>
        </div>
      </div>

      <div className="productall-container w-full flex flex-wrap justify-center gap-4 pt-5">
        {sortedProducts.length > 0 ? sortedProducts.map(product => (
          <div key={product._id} className="productall-box relative flex flex-col items-center justify-center w-full p-4 border bg-white rounded shadow-md hover:shadow-lg transition-shadow duration-300">
            <img src={product.image[0]} alt={product.title.en} className="w-full h-auto object-contain cursor-pointer" onClick={() => handleProductClick(product._id,product.slug)} />
            <h3 className="text-sm font-semibold text-center mb-1  mt-2 line-clamp-2 w-40 cursor-pointer" onClick={() => handleProductClick(product._id,product.slug)}>{product.title.en}</h3>
            <StarRating rating={product.rating} />
            <div className="flex justify-center items-center gap-2">
              <span className="text-lg text-primary-color">₹{product.prices.price}</span>
              <span className="text-sm line-through text-gray-600">₹{product.prices.originalPrice}</span>
            </div>
          </div>
        )) : (
          <div className="productall-box flex flex-col items-center justify-center w-full p-6 bg-white rounded-lg shadow-md">
            <img src={emptyData} alt="No data found" className="w-48 h-48 object-contain mb-4" />
            <p className="text-lg font-semibold">No Data Found</p>
          </div>
        )}
      </div>

      {/* Pagination Component */}

<div className="flex justify-center mt-16">
  <nav>
    <ul className="flex list-none">
      <li>
        <button
          onClick={() => onPageChange(currentPage - 1)}
          className={`px-3 py-2 bg-soft-color hover:bg-primary-color rounded border-none ${currentPage === 1 ? "cursor-not-allowed" : ""}`}
          disabled={currentPage === 1}
        >
          &lt;
        </button>
      </li>

      {/* Display first page and ellipsis if necessary */}
      {currentPage > 3 && (
        <>
          {/* <li>
            <button onClick={() => onPageChange(1)} className="px-3 py-2 cursor-pointer bg-soft-color hover:bg-primary-color border-none">1</button>
          </li> */}
          {currentPage > 4 && <li><span className="px-3 py-2 font-bold text-xl">...</span></li>}
        </>
      )}

      {/* Display the pages around the current page */}
      {Array.from({ length: totalPages }, (_, index) => index + 1)
        .filter(page =>
          page === 1 ||
          page === totalPages ||
          (page >= currentPage - 2 && page <= currentPage + 2)
        )
        .map(page => (
          <li key={page} className={`mx-1 ${page === currentPage ? "font-bold" : ""}`}>
            <button
              onClick={() => onPageChange(page)}
              className={`px-3 py-2 cursor-pointer ${page === currentPage ? "bg-primary-color font-bold text-[13px]" : "bg-soft-color"} hover:bg-primary-color border-none`}
            >
              {page}
            </button>
          </li>
        ))}

      {/* Display ellipsis and last page if necessary */}
      {currentPage < totalPages - 2 && (
        <>
          {currentPage < totalPages - 3 && <li><span className="px-3 py-2 font-bold text-xl">...</span></li>}
          {/* <li>
            <button onClick={() => onPageChange(totalPages)} className="px-3 py-2 cursor-pointer bg-soft-color hover:bg-primary-color border-none">{totalPages}</button>
          </li> */}
        </>
      )}

      <li>
        <button
          onClick={() => onPageChange(currentPage + 1)}
          className={`px-3 py-2 bg-soft-color hover:bg-primary-color rounded border-none ${currentPage === totalPages ? "cursor-not-allowed" : ""}`}
          disabled={currentPage === totalPages}
        >
          &gt;
        </button>
      </li>
    </ul>
  </nav>
</div>



    </div>
  );
};

export default ProductAll;
