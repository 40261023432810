import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import LoginPopup from "../Authentication/LoginPopup";
import LoadingSpinner from "../Loader/LoadingSpinner";
import arror_up from "../../assets/images/arror-up.svg";
import arror_rigth from "../../assets/images/arror-rigth.svg";
import arror_down from "../../assets/images/arror-down.svg";
import cate_icon from "../../assets/images/categaries_icon.svg";
import Overloy from "../../assets/images/Overlay.svg";
import CategoryService from "../Service/CategoryService";
import { ArrowsIcon, ArrowsIconRigth, ArrowsIconUp } from "../Container/Images";
// const getScreenHeightThreshold = (width) => {
//   if (width >= 2560) return 787;
//   if (width >= 1700) return 585;
//   if (width >= 1600) return 493;
//   if (width >= 1536) return 500;
//   if (width >= 1440) return 468;
//   if (width >= 1280) return 396;
//   if (width >= 768) return 500;
//   return 450;
// };

const Navbar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [categories, setCategories] = useState([]);
  const [openCategories, setOpenCategories] = useState({});
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const dropdownRef = useRef(null); 

  useEffect(() => {

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false); 
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    
    return () => {
      document.removeEventListener("mousedown", handleClickOutside); 
    };
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await CategoryService.fetchAllCategorise()
        const data = response.data;
        const childCategories = data.flatMap((category) => category.children);

        setCategories(childCategories);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const navigateWithLoading = (path) => {
    setIsLoading(true);
    setTimeout(() => {
      navigate(path);
      setIsLoading(false);
    }, 2000);
  };

  const handleHome = () => {
    setIsDropdownOpen(false);
    navigateWithLoading("/");
  };

  const handleProduct = () => {
    setIsDropdownOpen(false);
    navigateWithLoading("/product");
  };

  const handleAboutUs = () => {
    setIsDropdownOpen(false);
    navigateWithLoading("/aboutus");
  };

  const handleProfile = () => {
    const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";

    if (isLoggedIn) {
      navigate("/profile");
    } else {
      localStorage.setItem("intendedPath", "/profile");
      setIsLoginOpen(true);
      setIsDropdownOpen(false);
    }
  };

  const handleCloseLogin = () => {
    setIsLoginOpen(false);
  };

  const handleCategoryToggle = (categoryId) => {
    setOpenCategories((prevState) => ({
      ...prevState,
      [categoryId]: !prevState[categoryId],
    }));
  };

  // const handleCategoryClick = (category, id) => {
  //   setSelectedCategory(category);
  //   navigate(`/product/${category}`, { state: { category, id } });
  // };

  const handleCategoryClick = (category, id) => {

    console.log(`${id.name.en} ${id.parentName} Category=${category} ` )
    console.log(id )

   const slug = `${id.name.en} ${category} Category=${id.parentName} `

    setSelectedCategory(category);
    navigate(`/product/${slug}`, { state: { category, id } });
  };

  // Split categories into visible and additional
  const visibleCategories = categories.slice(0, 8);
  const additionalCategories = categories.slice(8);

  const renderCategory = (category) => {
    const hasChildren = category.children && category.children.length > 0;

    return (
      <div key={category._id} className="group relative text-black">
        <div
          className="flex items-center gap-2 cursor-pointer p-3 hover:bg-slate-100"
          onClick={() => handleCategoryToggle(category._id)}
        >
           {category.icon ? (
          <img src={category.icon} alt={`${category.name.en} icon`} className="h-8 w-8" />
        ) : (
          
      " "
        )}
          <span onClick={() => handleCategoryClick(category.name.en, category)}>
            {category.name.en}
          </span>
          {hasChildren && (
            <span className="ml-auto">
              {openCategories[category._id] ? <img src={ArrowsIconUp} className="h-5"/>: <img src={ArrowsIconRigth} className="h-5"/>}
            </span>
          )}
        </div>

        {hasChildren && openCategories[category._id] && (
          <div className="ml-4">
            {category.children.map((childCategory) => renderCategory(childCategory))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      {isLoading && <LoadingSpinner />}

      <div className="nav-bar-container flex justify-between bg-primary-color h-10 px-20 text-white">
        <div className="relative"  ref={dropdownRef}>
        <div
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className={`text-2xl cursor-pointer w-80 h-8 font-semibold text-center items-center flex gap-2 justify-start pt-2 ${
              isDropdownOpen
                ? "text-white bg-secondary-color"
                : "text-white bg-secondary-color"
            }`}
          >
            <img src={cate_icon} alt="Categories" className="ml-3 mb-2" />
            <span className="mb-1">Category</span>
          </div>
          {isDropdownOpen && (
          <div className="nav-bar-category-list absolute top-9 z-10  p-1">
            <div className="drop-down-nav bg-white p-2 shadow-2xl">
              {/* Category List with scroll */}
              <div className="category-list overflow-y-auto custom-scrollbar" style={{ maxHeight: "420px" }}>
                {categories.map((category) => renderCategory(category))}
              </div>
              
              {/* "View More" Button, stays fixed at the bottom */}
              <div className="drop-down-view justify-between text-moderate-color font-semibold p-3 cursor-pointer flex items-center gap-2">
                <span onClick={() => navigate("/product")}>View All</span>
                <img
                  src={ArrowsIcon}
                  alt=""
                  className="h-7 w-7 cursor-pointer"
                  onClick={handleProduct}
                />
              </div>
            </div>
          </div>
        )}
        </div>

        <div className="nav-bar flex gap-10 pr-16 uppercase">
          <div
            className={`flex items-center justify-center cursor-pointer relative w-28 h-10 text-center ${
              location.pathname === "/" ? "bg-secondary-color text-white" : "text-white"
            }`}
            onClick={handleHome}
          >
            Home
            {location.pathname === "/" && (
              <div className="nav-link-underline absolute bottom-0 left-0 w-full h-0.5 bg-white transform origin-left scale-x-100 transition-transform duration-300 ease-in-out"></div>
            )}
          </div>
          <div
            className={`flex items-center justify-center cursor-pointer relative w-28 h-10 text-center ${
              location.pathname === "/product"
                ? "bg-secondary-color text-white"
                : "text-white"
            }`}
            onClick={handleProduct}
          >
            Products
            {location.pathname === "/product" && (
              <div className="nav-link-underline absolute bottom-0 left-0 w-full h-0.5 bg-white transform origin-left scale-x-100 transition-transform duration-300 ease-in-out"></div>
            )}
          </div>
          <div
            className={`flex items-center justify-center cursor-pointer relative w-28 h-10 text-center ${
              location.pathname === "/aboutus"
                ? "bg-secondary-color text-white"
                : "text-white"
            }`}
            onClick={handleAboutUs}
          >
            About Us
            {location.pathname === "/aboutus" && (
              <div className="nav-link-underline absolute bottom-0 left-0 w-full h-0.5 bg-white transform origin-left scale-x-100 transition-transform duration-300 ease-in-out"></div>
            )}
          </div>
          <div
            className={`flex items-center justify-center cursor-pointer relative w-28 h-10 text-center ${
              location.pathname === "/profile"
                ? "bg-secondary-color text-white"
                : "text-white"
            }`}
            onClick={handleProfile}
          >
            Profile
            {location.pathname === "/profile" && (
              <div className="nav-link-underline absolute bottom-0 left-0 w-full h-0.5 bg-white transform origin-left scale-x-100 transition-transform duration-300 ease-in-out"></div>
            )}
          </div>
        </div>
      </div>

      <LoginPopup isOpen={isLoginOpen} onClose={handleCloseLogin} />
    </div>
  );
};

export default Navbar;
